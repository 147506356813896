<script setup>
import {Collapse} from 'vue-collapsed'

const isExpanded = ref(false)
const props = defineProps({
  title: {
    default: '324',
    type: String
  }
})
</script>

<template>
  <div class="bg-white w-full h-full rounded-d flex flex-col justify-start items-start">
    <div class="flex items-center justify-between w-full cursor-pointer pr-3" @click="isExpanded = !isExpanded">
      <button
          class="flex-grow w-full min-h-[40px] p-5 text-start relative md:text-xl"
          :class="{'border-b border-gray': isExpanded}">
        {{ title }}
      </button>
      <div class="lg:w-8 lg:h-8 w-6 h-6 rounded text-primary flex flex-center">
        <transition name="rotate" mode="out-in">
          <Icon name="simple-line-icons:plus" class="w-10 h-10" v-if="!isExpanded"/>
          <Icon name="simple-line-icons:minus" class="w-10 h-10" v-else/>
        </transition>
      </div>
    </div>
    <Collapse :when="isExpanded">
      <div class="p-5 text-default">
        <slot/>
      </div>
    </Collapse>
  </div>
</template>

<style>

.rotate-enter-active,
.rotate-leave-active {
  transition: all 0.1s ease-in-out;
}

.rotate-leave-to {
  transform: rotate(45deg);
}

.rotate-enter-from {
  transform: rotate(-45deg);
}
</style>